import { Controller as BaseController } from "@hotwired/stimulus";

export default class extends BaseController {
  static values = { show: Boolean }

	connect() {
    if (this.showValue) $(this.element).modal("show")
  }

  disconnect() {
    if (this.showValue) {
      $(this.element).modal("hide")
      $(".modal-backdrop").remove()
    }
  }
}
