import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["blockToToggle", "toggleAction", "inputToReset"]
  static values = { initialInput: { type: Number, default: null } }

  toggle() {
    if (this.hasToggleActionTarget) {
      this.toggleActionTarget.classList.add("d-none")
    }
    this.blockToToggleTargets.forEach((element) => {
      element.classList.toggle("d-none");
    });
  }

  resetToggle() {
    if (this.hasToggleActionTarget) {
      this.toggleActionTarget.classList.remove("d-none")
    }
    this.blockToToggleTargets.forEach((element) => {
      element.classList.add("d-none");
    });
  }

  toggleWithInitialButton(event) {
    const container = this.blockToToggleTarget;

    if (container) {
      const button = event.target;
      const isHidden = container.classList.contains("d-none");
      const inputToReset = this.inputToResetTarget;
      container.classList.toggle("d-none", !isHidden);
      button.innerText = isHidden ? event.params.toggleText : event.params.initialText;

      if (inputToReset) {
        inputToReset.value = isHidden ? this.initialInputValue : "";
      }
    }
  }
}
