import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "popover", "popoverButton", "housingPopoverButton", "toggleButton", "toggleButtonHiddenField"]
  static outlets = ["venue-search"]

  submitToggle() {
    let shouldSubmit = false

    this.inputTargets.forEach((input) => {
      if (input.type == "checkbox" || input.type == "radio" || input.type == "hidden") {
        const booleanCheckboxInput = Array.from((this.venueSearchOutlet.formTarget.elements)).find((f) => (f.name == input.name && f.type == "checkbox" && f.value == "1"))

        if (["0", "1"].includes(input.value) && booleanCheckboxInput) {
          const inputHasChanged = input.value == "1" && !booleanCheckboxInput.checked || input.value == "0" && booleanCheckboxInput.checked

          shouldSubmit = inputHasChanged
          booleanCheckboxInput.checked = input.value == "1"
        } else {
          const venueSearchFormInput = Array.from((this.venueSearchOutlet.formTarget.elements)).find((f) => (f.name == input.name && f.value == input.value))
          if (!venueSearchFormInput) return

          shouldSubmit = input.checked != venueSearchFormInput.checked
          venueSearchFormInput.checked = input.checked
        }
      } else {
        const venueSearchFormInput = Array.from((this.venueSearchOutlet.formTarget.elements)).find((f) => f.name == input.name)
        if (!venueSearchFormInput) return

        shouldSubmit = input.value != venueSearchFormInput.value
        venueSearchFormInput.value = input.value
      }
    })


    if (!shouldSubmit) return

    this.venueSearchOutlet.pushFormParamsToUrl()
    this.venueSearchOutlet.submitForm()
  }

  submitPopover() {
    let shouldSubmit = false

    this.inputTargets.forEach((input) => {
      if (input.type == "checkbox" || input.type == "radio") {
        const venueSearchFormInput = Array.from((this.venueSearchOutlet.formTarget.elements)).find((f) => (f.name == input.name && f.value == input.value))
        if (!venueSearchFormInput) return
        if (input.checked != venueSearchFormInput.checked) shouldSubmit = true

        venueSearchFormInput.checked = input.checked
      } else {
        const venueSearchFormInput = Array.from((this.venueSearchOutlet.formTarget.elements)).find((f) => f.name == input.name)
        if (!venueSearchFormInput) return
        if (input.value != venueSearchFormInput.value) shouldSubmit = true

        venueSearchFormInput.value = input.value
      }
    })

    if (!shouldSubmit) return

    this.venueSearchOutlet.pushFormParamsToUrl()
    this.venueSearchOutlet.submitForm()
  }

  submitOnClose(event) {
    const shouldSubmit = !this.popoverTarget.contains(event.target)
                          && !(!this.element.contains(event.target) && this.popoverTarget.parentElement.classList.contains("d-none"))
                            && !(this.element.contains(event.target) && !this.popoverTarget.parentElement.classList.contains("d-none"))
    if (!shouldSubmit) return

    this.submitPopover()
  }

  submitAndClose(event) {
    this.popoverTarget.parentElement.classList.add("d-none")
    this.submitPopover()
  }

  toggleToggleButton(event) {
    if (event.params.fieldKind == "array") {
      this.toggleButtonHiddenFieldTarget.checked = !this.toggleButtonHiddenFieldTarget.checked
    } else if (event.params.fieldKind == "boolean") {
      if (this.toggleButtonHiddenFieldTarget.value == "1") {
        this.toggleButtonHiddenFieldTarget.value = "0"
      } else {
        this.toggleButtonHiddenFieldTarget.value = "1"
      }
    }

    this.toggleButtonTarget.classList.toggle("btn-outline-dark")
    this.toggleButtonTarget.classList.toggle("focus")
    this.toggleButtonTarget.classList.toggle("btn-check")
    this.submitToggle()
  }
}
