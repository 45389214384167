import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select2", "form"]

  connect() {
    this.goToBooking()
    $(this.select2Target).on("change", () => this.submit())
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  goToBooking() {
    document.querySelectorAll("td").forEach(td => {
      td.addEventListener("click", (e) => {
        if (!e.target.classList.contains("booking_status")) {
          const bookingId = e.target.closest("tr").dataset.bookingId
          window.open(`bookings/${bookingId}`, "_blank")
        }
      })
    })
  }
}