import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]
  static outlets = ["navbar-search-form", "navbar-search-form--placeholder"]
  static values = { queryInputPlaceholder: Object }

  selectCategory(event) {
    this.navbarSearchFormOutlet.searchCategoryInputTarget.value = event.params.categoryValue

    this.toggleSearchCategoryButtons(event)
    this.updateQueryContainer(event)
    this.togglePackageCategoryContainerPresence(event)
    this.updatePlaceholderValues()

    if (event.params.categoryValue != "venue") {
      this.navbarSearchFormOutlet.packageCategoryInputTarget.value = ""
    }
  }

  // Toggles the active class on the search category buttons
  toggleSearchCategoryButtons(event) {
    const selectedButton = this.buttonTargets.find(button => button.dataset["navbarSearchForm-CategorySelectorCategoryValueParam"] == event.params.categoryValue)

    this.buttonTargets.forEach(button => {
      button.classList.remove("btn-primary")
      button.classList.remove("btn-light")
      button.classList.add(button == selectedButton ? "btn-primary" : "btn-light")
    })
  }

  // Updating the class on the query container allows us to style the search form differently based on the selected category
  updateQueryContainer(event) {
    this.navbarSearchFormOutlet.queryContainerTarget.classList.remove("venue")
    this.navbarSearchFormOutlet.queryContainerTarget.classList.remove("meal")
    this.navbarSearchFormOutlet.queryContainerTarget.classList.remove("activity")
    this.navbarSearchFormOutlet.queryContainerTarget.classList.add(event.params.categoryValue)

    this.navbarSearchFormPlaceholderOutlet.queryContainerTarget.classList.remove("venue")
    this.navbarSearchFormPlaceholderOutlet.queryContainerTarget.classList.remove("meal")
    this.navbarSearchFormPlaceholderOutlet.queryContainerTarget.classList.remove("activity")
    this.navbarSearchFormPlaceholderOutlet.queryContainerTarget.classList.add(event.params.categoryValue)
  }

  // If the selected category is "venue", we show the package category container, otherwise we hide it
  togglePackageCategoryContainerPresence(event) {
    if (event.params.categoryValue == "venue") {
      this.navbarSearchFormOutlet.packageCategoryDividerTarget.classList.remove("d-none")
      this.navbarSearchFormOutlet.packageCategoryContainerTarget.classList.remove("d-none")

      this.navbarSearchFormPlaceholderOutlet.packageCategoryDividerTarget.classList.remove("d-none")
      this.navbarSearchFormPlaceholderOutlet.packageCategoryContainerTarget.classList.remove("d-none")
    } else {
      this.navbarSearchFormOutlet.packageCategoryDividerTarget.classList.add("d-none")
      this.navbarSearchFormOutlet.packageCategoryContainerTarget.classList.add("d-none")

      this.navbarSearchFormPlaceholderOutlet.packageCategoryDividerTarget.classList.add("d-none")
      this.navbarSearchFormPlaceholderOutlet.packageCategoryContainerTarget.classList.add("d-none")
    }
  }

  // Update the placeholder value for the query input based on the selected category
  updatePlaceholderValues() {
    this.navbarSearchFormOutlet.queryTarget.placeholder = this.queryInputPlaceholderValue[event.params.categoryValue]
    this.navbarSearchFormOutlet.updatePlaceholderValues()
  }
}
