import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { reviewableType: String }

  connect() {
    this.initFilters(this.reviewableTypeValue)
    this.initDateFilter(this.reviewableTypeValue)
  }

  initFilters(reviewableType) {
    $(`#search_review_data_${reviewableType}`).on("keyup change", function(e) {
      if ($(e.target).val() == "") {
        $(`#q_event_primary_user_id_eq, #q_event_company_id_eq, #q_reviewable_id_eq, #search_review_data`).each(function(_, target) {
          target.value = null;
        });
        $(`#review_search_${reviewableType}`).submit();
      }
    });
  }

  initDateFilter(reviewableType) {
    var filterForm = $(`#review_search_${reviewableType}`);
    var startDate = $(`#review_created_at_gteq_${reviewableType}`);
    var toDate = $(`#review_created_at_lteq_${reviewableType}`);
    var calendarOptions = {
      language: $('html').attr('lang') || 'fr',
      autoclose: true
    };

    startDate.datepicker(calendarOptions);
    toDate.datepicker(calendarOptions);

    startDate.on('changeDate', function(e) {
      toDate.datepicker("show");
    });

    toDate.on('changeDate', function(e) {
      filterForm.submit();
    });
  }
}




  
