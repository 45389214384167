import { Controller as BaseController } from "@hotwired/stimulus";

export default class extends BaseController {
  static targets = ["item", "showMore", "input"];

  showMore() {
    this.itemTargets.forEach((item) => {
      item.classList.remove("d-none");
      setTimeout(() => {
        item.classList.remove("visibility-hidden");
      }, 100);
    });

    this.showMoreTarget.classList.add("d-none");
  }

  toggleRadioButton(event) {
    event.preventDefault();

    const input = this.inputTargets.find((input) => input.id === event.params.inputId);
    if (!input) return

    if (input.checked) {
      input.checked = false;
    } else {
      input.checked = true;
    }

    // this data param must be set in the component call when required
    if (event.params.autosubmit == true) {
      input.closest("form").requestSubmit();
    }
  }

}
