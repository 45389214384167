import { ApplicationController, useDebounce } from 'stimulus-use'

export default class extends ApplicationController {
  static debounces = ['updateSuggestions']

  static targets = [
    "input",
    "suggestionDropdown",
    "placeServicesNode",
    "suggestedAddressTemplate",
    "suggestedEstablishmentTemplate",
    "defaultSuggestionsTemplate",
  ]

  static values = {
    autocompleteCountries: Array,
    dropdownTitle: String
  }

  // This component controller works as an outlet for another controller.
  // The other controller must have a method called `selectGooglePlace` that receives a placeData object.
  // This method must be attributed to the outlet in the other controller.
  //
  // formGoogleAutocompleteInputOutletConnected(outlet, element) {
  //   outlet.selectGooglePlace = this.selectGooglePlace.bind(this)
  // }
  //
  // selectGooglePlace(placeData) {
  //   do things
  // }

  connect() {
    useDebounce(this, { wait: 200 })
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }

  updateSuggestions() {
    if (this.inputTarget.value.length > 2) {
      const params = { input: this.inputTarget.value, componentRestrictions: { country: this.autocompleteCountriesValue } }
      this.autocompleteService.getPlacePredictions(params, this.fetchGooglePlaces.bind(this))
    } else {
      this.appendDefaultSuggestions()
    }
    this.openSuggestionDropdown()
  }

  async fetchGooglePlaces(predictions) {
    this.suggestionDropdownTarget.innerHTML = ""

    if (predictions != null && predictions.length > 0) {
      this.placesService = new google.maps.places.PlacesService(this.placeServicesNodeTarget);

      predictions.forEach(placePrediction => {
        const isEstablishment = placePrediction.types?.includes("establishment")
        const template = isEstablishment ? this.suggestedEstablishmentTemplateTarget : this.suggestedAddressTemplateTarget
        const item = template.content.firstElementChild.cloneNode(true);

        if (isEstablishment) {
          item.children[1].firstElementChild.textContent = placePrediction.structured_formatting.main_text;
          item.children[1].lastElementChild.textContent = placePrediction.structured_formatting.secondary_text;
        } else {
          item.children[1].firstElementChild.textContent = placePrediction.description;
        }

        item.addEventListener("click", () => {
          this.placesService.getDetails({ placeId: placePrediction.place_id }, (placeData) => {
            this.selectGooglePlace(placeData)
            this.suggestionDropdownTarget.classList.add("d-none");
          })
        })

        this.suggestionDropdownTarget.append(item);
      })
    } else {
      this.appendDefaultSuggestions()
    }
  }

  appendDefaultSuggestions() {
    this.suggestionDropdownTarget.innerHTML = ""

    const titleItem = document.createElement("div")
    titleItem.classList.add("p-2", "font-weight-bolder")
    titleItem.textContent = this.dropdownTitleValue
    this.suggestionDropdownTarget.append(titleItem)

    Array.from(this.defaultSuggestionsTemplateTarget.content.children).forEach(child => {
      const item = child.cloneNode(true)
      const formattedAddress = item.children[1].firstElementChild.textContent

      item.addEventListener("click", () => {
        this.inputTarget.value = formattedAddress
        this.inputTarget.setAttribute("value", formattedAddress)

        this.suggestionDropdownTarget.classList.add("d-none");
        this.selectDefaultSuggestion(formattedAddress)
        this.inputTarget.blur()
      })

      this.suggestionDropdownTarget.append(item)
    })
  }

  selectDefaultSuggestion() {
    // Implement this method in the other controller
  }

  focusNextSuggestion() {
    const selectedSuggestionIndex = Array.from(this.suggestionDropdownTarget.children).indexOf(this.selectedSuggestion) || 0
    const nextSuggestionIndex = selectedSuggestionIndex + 1 >= this.suggestionDropdownTarget.children.length ? 0 : selectedSuggestionIndex + 1
    const nextSuggestion = this.suggestionDropdownTarget.children[nextSuggestionIndex]

    this.selectedSuggestion?.classList?.remove("selected")

    nextSuggestion.classList.add("selected")
    this.selectedSuggestion = nextSuggestion
  }

  focusPreviousSuggestion() {
    const selectedSuggestionIndex = Array.from(this.suggestionDropdownTarget.children).indexOf(this.selectedSuggestion) || 0
    const previousSuggestionIndex = selectedSuggestionIndex - 1 < 0 ? this.suggestionDropdownTarget.children.length - 1 : selectedSuggestionIndex - 1
    const previousSuggestion = this.suggestionDropdownTarget.children[previousSuggestionIndex]

    this.selectedSuggestion?.classList?.remove("selected")

    previousSuggestion.classList.add("selected")
    this.selectedSuggestion = previousSuggestion
  }

  selectSuggestion() {
    this.selectedSuggestion?.click()
  }

  openSuggestionDropdown() {
    this.suggestionDropdownTarget.classList.remove("d-none")
  }

  closeSuggestionDropdown(event) {
    if (event instanceof PointerEvent) {
      if (this.suggestionDropdownTarget.contains(event?.target)) return
      if (this.inputTarget.contains(event?.target)) return
    }

    this.suggestionDropdownTarget.classList.add("d-none");
  }
}
