import { ApplicationController, useDebounce } from 'stimulus-use'


const GOOGLE_PLACE_TYPES = [
  "tourist_attraction",
  "point_of_interest",
  "establishment",
  "premise",
  "street",
  "route",
  "street_address",
  "locality",
  "postal_code",
  "sublocality",
  "neighborhood",
  "administrative_area_level_2",
  "administrative_area_level_1",
  "country"
]

const ADDRESS_TYPE_COMPONENTS = {
  establishment: "addressEstablishmentInput",
  locality: "addressLocalityInput",
  administrative_area_level_1: "addressAdministrativeAreaLevel1Input",
  administrative_area_level_2: "addressAdministrativeAreaLevel2Input",
  country: "addressCountryInput",
  postal_code: "addressZipCodeInput"
}

export default class extends ApplicationController {
  static debounces = ['focusNextInput']

  static targets = [
    "input", "query", "packageCategoryInput", "packageCategoryIcon", "packageCategoryDropdown", "packageCategoryItem",
    "searchCategoryInput", "searchCategoryItem", "mobilePackageCategoryWrapper",
    "guestsInput", "submitButton", "categoriesWrapper", "inputsWrapper", "form", "categoryInput", "mobileFormH4",
    "googlePlaceTypeInput", "googlePlaceNameInput", "googlePlaceIdInput", "googlePlaceLatInput", "googlePlaceLngInput",
    "addressEstablishmentInput", "addressLocalityInput", "addressAdministrativeAreaLevel1Input",
    "addressAdministrativeAreaLevel2Input", "addressCountryInput", "addressZipCodeInput",
    "packageCategoryDivider", "packageCategoryContainer", "queryContainer"
  ]

  static values = {
    autocompleteCountries: Array,
    mobile: Boolean,
    venueSearchPath: String,
    mealSearchPath: String,
    activitySearchPath: String,
    queryInputPlaceholder: Object
  }

  static outlets = ["venue-search", "form--google-autocomplete-input", "navbar-search-form--placeholder"]

  connect() {
    useDebounce(this, { wait: 200 })
  }



  formGoogleAutocompleteInputOutletConnected(outlet, element) {
    outlet.selectGooglePlace = this.selectGooglePlace.bind(this)
    outlet.selectDefaultSuggestion = this.selectDefaultSuggestion.bind(this)
  }

  selectDefaultSuggestion(formattedAddress) {
    this.googlePlaceTypeInputTarget.value = "locality"
    this.googlePlaceNameInputTarget.value = null
    this.googlePlaceIdInputTarget.value = null
    this.googlePlaceLatInputTarget.value = null
    this.googlePlaceLngInputTarget.value = null
    this.updatePlaceholderValues()
    this.focusNextInput({ target: this.queryTarget })
  }

  selectGooglePlace(placeData) {
    const placeTypes = placeData.types || null
    const isEstablishment = placeTypes?.includes("establishment")
    const formattedAddress = [(isEstablishment ? placeData.name : null), placeData.formatted_address].filter(Boolean).join(", ")

    const selectedCategory = this.searchCategoryInputTarget.value

    Object.entries(ADDRESS_TYPE_COMPONENTS).forEach(([type, target]) => {
      this[target + "Target"].value = placeData.address_components?.find(component => component.types.includes(type))?.long_name || null
    })

    if (placeTypes && selectedCategory == "venue") {
      this.googlePlaceTypeInputTarget.value = placeTypes.find(type => GOOGLE_PLACE_TYPES.includes(type)) || null

      // We only want to set the name and place_id if the place is an establishment.
      // These values are used to try matching venues in the database.
      this.googlePlaceNameInputTarget.value = isEstablishment ? placeData.name : null
      this.googlePlaceIdInputTarget.value = isEstablishment ? placeData.place_id : null

      // We need to set the lat/lng values in all cases because we use them to set the specific location pin on the map.
      this.googlePlaceLatInputTarget.value = placeData.geometry.location.lat()
      this.googlePlaceLngInputTarget.value = placeData.geometry.location.lng()
    }

    if (this.queryTarget.value == "" || formattedAddress != null) {
      this.queryTarget.value = formattedAddress
      this.queryTarget.setAttribute("value", formattedAddress)
    }

    this.queryTarget.blur()
    this.updatePlaceholderValues()

    this.focusNextInput({ target: this.queryTarget })
  }

  toggleCategoryDropdown() {
    this.packageCategoryDropdownTarget.classList.toggle("d-none")
    this.packageCategoryInputTarget.focus()
  }

  openCategoryDropdown() {
    this.packageCategoryDropdownTarget.classList.remove("d-none")
    this.formGoogleAutocompleteInputOutlet.closeSuggestionDropdown()
  }

  closeCategoryDropdown(event) {
    if (!this.packageCategoryDropdownTarget.checkVisibility()) return
    if (this.packageCategoryDropdownTarget.contains(event?.target)) return
    if (this.packageCategoryInputTarget.contains(event?.target)) return

    this.packageCategoryDropdownTarget.classList.add("d-none");
  }

  focusNextCategory() {
    const selectedPackageCategoryIndex = Array.from(this.packageCategoryDropdownTarget.children).indexOf(this.selectedPackageCategory) || 0
    const nextPackageCategoryIndex = selectedPackageCategoryIndex + 1 >= this.packageCategoryDropdownTarget.children.length ? 0 : selectedPackageCategoryIndex + 1
    const nextPackageCategory = this.packageCategoryDropdownTarget.children[nextPackageCategoryIndex]

    this.selectedPackageCategory?.classList?.remove("selected")

    nextPackageCategory.classList.add("selected")
    this.selectedPackageCategory = nextPackageCategory
  }

  focusPreviousCategory() {
    const selectedPackageCategoryIndex = Array.from(this.packageCategoryDropdownTarget.children).indexOf(this.selectedPackageCategory) || 0
    const previousPackageCategoryIndex = selectedPackageCategoryIndex - 1 < 0 ? this.packageCategoryDropdownTarget.children.length - 1 : selectedPackageCategoryIndex - 1
    const previousPackageCategory = this.packageCategoryDropdownTarget.children[previousPackageCategoryIndex]

    this.selectedPackageCategory?.classList?.remove("selected")

    previousPackageCategory.classList.add("selected")
    this.selectedPackageCategory = previousPackageCategory
  }

  selectCategory(event) {
    if (event instanceof MouseEvent || event instanceof PointerEvent) {
      this.selectedPackageCategory = this.packageCategoryItemTargets.find(target => target.contains(event.target))
    }

    const selectedTarget = this.selectedPackageCategory

    if (selectedTarget) {
      this.packageCategoryInputTarget.value = selectedTarget.dataset.value
      this.updatePlaceholderValues()
    }

    this.closeCategoryDropdown()
    this.focusNextInput({ target: this.packageCategoryInputTarget })
  }

  selectSearchCategory(event) {
    if (!this.mobileValue) return

    const selectedTarget = this.searchCategoryItemTargets.find(target => target.dataset.navbarSearchFormCategoryParam == event.params.category)

    this.searchCategoryInputTarget.value = event.params.category

    this.categoriesWrapperTarget.classList.add("d-none")
    this.inputsWrapperTarget.classList.remove("d-none")
    this.mobileFormH4Targets.forEach(target => {
      target.classList.add("d-none")

      if (target.dataset.value == selectedTarget.dataset.navbarSearchFormCategoryParam) {
        target.classList.remove("d-none")
      }
    })

    if (event.params.category != "venue") {
      this.mobilePackageCategoryWrapperTarget.classList.add("d-none")
    } else {
      this.mobilePackageCategoryWrapperTarget.classList.remove("d-none")
    }
  }

  focusNextInput(event) {
    if (this.hasVenueSearchOutlet && !this.mobileValue) {
      this.submitForm()
    } else if (event.target === this.queryTarget && this.packageCategoryInputTarget.checkVisibility()) {
      this.packageCategoryInputTarget.focus()
    } else if (event.target === this.queryTarget) {
      this.guestsInputTarget.focus()
    } else if (event.target === this.packageCategoryInputTarget) {
      this.guestsInputTarget.focus()
    } else if (event.target === this.guestsInputTarget) {
      this.submitButtonTarget.focus()
    }
  }

  showCategoriesWrapper() {
    this.categoriesWrapperTarget.classList.remove("d-none")
    this.inputsWrapperTarget.classList.add("d-none")
  }

  submitForm() {
    const searchCategory = this.searchCategoryInputTarget.value

    if (!this.validateForm()) return

    if (searchCategory === "venue" || searchCategory === "meal") {
      if (this.hasVenueSearchOutlet) {
        return this.pushParamsToVenueSearchOutletFormAndSubmit()
      } else {
        this.formTarget.action = this.venueSearchPathValue
        this.categoryInputTarget.value = searchCategory
      }
    } else if (searchCategory === "activity") {
      this.formTarget.action = this.activitySearchPathValue
      this.categoryInputTarget.value = "activity"
    }

    this.formTarget.submit()
  }

  submitFormAndCloseModal() {
    if (!this.validateForm()) return

    this.submitForm()
    $('#navbar_search_form_modal').modal('hide')
  }

  validateForm() {
    const searchCategory = this.searchCategoryInputTarget.value

    if (searchCategory == "venue" && !this.packageCategoryInputTarget.value) {
      this.packageCategoryInputTarget.classList.add("is-invalid")
      return false
    }

    return true
  }

  pushParamsToVenueSearchOutletFormAndSubmit() {
    var shouldSubmit = false
    const shouldUpdateGoogleInputs = this.searchCategoryInputTarget.value == "venue" || this.searchCategoryInputTarget.value == "meal"

    this.inputTargets.forEach((e) => {
      const input = Array.from(this.venueSearchOutlet.formTarget.elements).find((f) => f.name == e.name)
      const shouldUpdateInput = !e.name.includes("google_place") || (shouldUpdateGoogleInputs && e.name.includes("google_place"))

      if (input && shouldUpdateInput) {
        if (e.value != input.value) {
          shouldSubmit = true

          if (e.name == "package_category" || e.name == "search_category") {
            this.updateVenueSearchModalPackagesSection(e)
          }

          if (e.name == "search_category") {
            this.toggleVenueSearchModalHousingSectionVisibility()
            this.toggleVenueSearchModalStandingTagsSectionVisibility()
            this.toggleVenueSearchModalKindTagsSectionVisibility()
          }
        }
        input.value = e.value
      }
    })

    if (this.venueSearchOutlet.formTarget.elements.query.value) this.venueSearchOutlet.formTarget.elements.moved_map.value = null
    if (!shouldSubmit) return

    this.venueSearchOutlet.pushFormParamsToUrl()
    this.venueSearchOutlet.submitForm()
  }

  // This method is called when the user selects a package category from the dropdown
  // It show/hide the package inputs in the venue_search_modal based on the selected category
  // It also updates the package input values based on the selected category
  updateVenueSearchModalPackagesSection(packageCategoryInput) {
    this.venueSearchOutlet.modalPackagesButtonControlTargets.forEach((f) => {
      f.classList.add("d-none")
      f.classList.remove("d-block")

      if (f.dataset.categoryValue == packageCategoryInput.value || f.dataset.categoryValue == this.searchCategoryInputTarget.value) {
        f.classList.remove("d-none")
        f.classList.add("d-block")
      }
    })

    if (packageCategoryInput.value == "residential") {
      this.venueSearchOutlet.modalPackagesSectionTarget.classList.add("d-none")
      this.venueSearchOutlet.modalPackagesSectionTarget.classList.remove("d-flex")
    } else {
      this.venueSearchOutlet.modalPackagesSectionTarget.classList.remove("d-none")
      this.venueSearchOutlet.modalPackagesSectionTarget.classList.add("d-flex")
    }

    const packageInputs = this.venueSearchFormElementsArray().filter((f) => f.name == "package")
    packageInputs.forEach((packageInput) => {
      packageInput.checked = packageCategoryInput.value == "residential" && packageInput.value == "residential_seminar"
    })
  }

  updatePlaceholderValues() {
    if (this.mobileValue) return

    const queryButton = this.navbarSearchFormPlaceholderOutlet.queryButtonTarget;
    const packageCategoryButton = this.navbarSearchFormPlaceholderOutlet.packageCategoryButtonTarget;
    const guestsButton = this.navbarSearchFormPlaceholderOutlet.guestsButtonTarget;

    const selectedPackageCategory = this.packageCategoryInputTarget.options[this.packageCategoryInputTarget.selectedIndex]
    const packageCategoryText = selectedPackageCategory.textContent
    const packageCategoryValue = selectedPackageCategory.value

    if (this.queryTarget.value) {
      queryButton.children[0].textContent = this.queryTarget.value
      queryButton.children[0].classList.remove("text-muted")
      queryButton.children[0].classList.remove("font-weight-bold")
    } else {
      queryButton.children[0].textContent = this.queryTarget.placeholder
      queryButton.children[0].classList.add("text-muted")
      queryButton.children[0].classList.add("font-weight-bold")
    }

    if (packageCategoryValue) {
      packageCategoryButton.children[0].textContent = packageCategoryText
      packageCategoryButton.children[0].classList.remove("text-muted")
      packageCategoryButton.children[0].classList.remove("font-weight-bold")
    } else {
      packageCategoryButton.children[0].textContent = packageCategoryText
      packageCategoryButton.children[0].classList.add("text-muted")
      packageCategoryButton.children[0].classList.add("font-weight-bold")
    }

    if (this.guestsInputTarget.value) {
      guestsButton.children[0].textContent = this.guestsInputTarget.value
      guestsButton.children[0].classList.remove("text-muted")
      guestsButton.children[0].classList.remove("font-weight-bold")
    } else {
      guestsButton.children[0].textContent = this.guestsInputTarget.placeholder
      guestsButton.children[0].classList.add("text-muted")
      guestsButton.children[0].classList.add("font-weight-bold")
    }
  }

  mutedPlaceholder(value) {
    return `<span class="d-block text-muted font-weight-normal text-truncate">${value}</span>`
  }

  toggleVenueSearchModalHousingSectionVisibility() {
    if (this.searchCategoryInputTarget.value == "meal") {
      this.venueSearchOutlet.formTarget.elements.beds_count.value = null
      this.venueSearchOutlet.formTarget.elements.bedrooms_count.value = null

      this.venueSearchOutlet.modalHousingSectionTarget.classList.add("d-none")
      this.venueSearchOutlet.modalHousingSectionTarget.classList.remove("d-flex")
    } else {
      this.venueSearchOutlet.modalHousingSectionTarget.classList.remove("d-none")
      this.venueSearchOutlet.modalHousingSectionTarget.classList.add("d-flex")
    }
  }

  toggleVenueSearchModalStandingTagsSectionVisibility() {
    if (this.searchCategoryInputTarget.value == "meal") {
      const standingTagsInputs = this.venueSearchFormElementsArray().filter((f) => f.name == "standing_tags_ids[]")
      standingTagsInputs.forEach((input) => input.checked = false)

      this.venueSearchOutlet.modalStandingTagsSectionTarget.classList.add("d-none")
      this.venueSearchOutlet.modalStandingTagsSectionTarget.classList.remove("d-block")
    } else {
      this.venueSearchOutlet.modalStandingTagsSectionTarget.classList.remove("d-none")
      this.venueSearchOutlet.modalStandingTagsSectionTarget.classList.add("d-block")
    }
  }

  toggleVenueSearchModalKindTagsSectionVisibility() {
    if (this.searchCategoryInputTarget.value == "meal") {
      const kindTagsInputs = this.venueSearchFormElementsArray().filter((f) => f.name == "kind_tags_ids[]")
      kindTagsInputs.forEach((input) => input.checked = false)

      this.venueSearchOutlet.modalKindTagsSectionTarget.classList.add("d-none")
      this.venueSearchOutlet.modalKindTagsSectionTarget.classList.remove("d-block")
    } else {
      this.venueSearchOutlet.modalKindTagsSectionTarget.classList.remove("d-none")
      this.venueSearchOutlet.modalKindTagsSectionTarget.classList.add("d-block")
    }
  }

  venueSearchFormElementsArray() {
    return Array.from(this.venueSearchOutlet.formTarget.elements)
  }
}
