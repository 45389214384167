import { Controller } from "@hotwired/stimulus";
import { useClickOutside, useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = ["results", "input"];
  static debounces = ["search"];
  static values = {
    url: String,
    indexes: Array,
    limitPerType: Number,
    source: String,
    token: String,
    timestamp: String,
    signature: String,
    admin: String
  };

  connect() {
    useClickOutside(this)
    useDebounce(this) // default debounce time is 200ms
  }

  search() {
    this.inputTarget.value.length > 2 && this.fetchTurboStream();
  }

  clickOutside() {
    this.resultsTarget.innerHTML = ""
  }

  updateInput(event) {
    this.inputTarget.value = event.params.value
    this.resultsTarget.innerHTML = ""
  }

  urlWithParams() {
    const url = new URL(this.urlValue)
    this.indexesValue.forEach(index => {
      url.searchParams.append("index[]", index)
    });
    url.searchParams.append("limit_per_type", this.limitPerTypeValue);
    url.searchParams.append("query", this.inputTarget.value);
    url.searchParams.append("source", this.sourceValue);

    if (this.tokenValue !== "") {
      url.searchParams.append("token", this.tokenValue);
    } else {
      url.searchParams.append("timestamp", this.timestampValue);
      url.searchParams.append("signature", this.signatureValue);
      url.searchParams.append("admin", this.adminValue);
    }

    return url
  }

  fetchTurboStream() {
    if (this.signal) {
      this.abortController.abort()
    }

    this.abortController = new AbortController()
    this.signal = this.abortController.signal

    fetch(this.urlWithParams(), {
      method: "GET",
      signal: this.signal,
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      }
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
      .catch(error => {});
  }
}
